
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";

@Component
export default class AboutUs extends Vue {
  @Prop({ required: true, type: Number })
  private progress!: number;

  @Ref() private readonly video!: HTMLVideoElement;
  private videoReady = false;
  private videoPlayed = false;
  private timeout = 0;
  videoSource: string | null = null;
  videoPoster: string | null = null;
  private videoSourceChanged = false;

  private created() {
    this.setVideoUrl();
    window.addEventListener("orientationchange", this.setVideoUrl);
    window.addEventListener("resize", this.setVideoUrl);
  }

  private destroyed() {
    clearInterval(this.timeout);
    window.removeEventListener("orientationchange", this.setVideoUrl);
    window.removeEventListener("resize", this.setVideoUrl);
  }

  private setVideoUrl() {
    this.videoSource =
      window.innerHeight > window.innerWidth
        ? "/videos/our-story-portrait"
        : "/videos/our-story";

    this.videoPoster =
      window.innerHeight > window.innerWidth
        ? require("@/assets/img/posters/our-story-portrait.jpg")
        : require("@/assets/img/posters/our-story.jpg");
  }

  @Watch("videoSource")
  private onVideoSourceChanged(newVal: string, oldVal: string | null) {
    if (oldVal !== null) {
      this.videoSourceChanged = true;
    }
  }

  private mounted() {
    this.video.load();
    this.video.onloadedmetadata = () => (this.videoReady = true);

    this.timeout = setInterval(() => {
      if (this.video && this.videoSourceChanged) {
        this.video.pause();
        this.video.load();
        this.videoSourceChanged = false;
        this.videoPlayed = false;
      }
    }, 1000);
  }

  @Watch("progress")
  private onProgressChange(val: number) {
    if (val > 0.2 && this.videoReady && !this.videoPlayed) {
      this.video.play();
      this.videoPlayed = true;
    }
  }
}
