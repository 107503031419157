
import { Component, Vue, Watch, Ref } from "vue-property-decorator";

@Component
export default class Home extends Vue {
  @Ref() video!: HTMLVideoElement;
  videoEnded = false;
  private timeout = 0;
  videoSource: string | null = null;
  videoPoster: string | null = null;
  private videoSourceChanged = false;
  videoMuted = true;

  private created() {
    this.setVideoUrl();
    window.addEventListener("orientationchange", this.setVideoUrl);
    window.addEventListener("resize", this.setVideoUrl);
  }

  scrollToNextSlide() {
    this.skipVideo();
    document
      .getElementById("slide-about-us")
      ?.scrollIntoView({ behavior: "smooth" });
  }

  mounted() {
    this.timeout = setInterval(() => {
      if (this.video && this.videoSourceChanged) {
        this.video.pause();
        this.video.load();
        this.videoSourceChanged = false;
      }
    }, 1000);
  }

  destroyed() {
    clearInterval(this.timeout);
    window.removeEventListener("orientationchange", this.setVideoUrl);
    window.removeEventListener("resize", this.setVideoUrl);
  }

  skipVideo() {
    if (!this.video) return;
    this.videoMuted = true;
    this.video.pause();
    this.videoEnded = true;
  }

  toggleMute() {
    this.video.muted = !this.video.muted;
    this.videoMuted = this.video.muted;
  }

  replayVideo() {
    this.videoEnded = false;
  }

  private setVideoUrl() {
    this.videoSource =
      window.innerHeight > window.innerWidth
        ? "/videos/home-portrait.mp4"
        : "/videos/home-landscape.mp4";
    this.videoPoster =
      window.innerHeight > window.innerWidth
        ? require("@/assets/img/posters/home-portrait.png")
        : require("@/assets/img/posters/home.png");
  }

  @Watch("videoSource")
  private onVideoSourceChanged(newVal: string, oldVal: string | null) {
    if (oldVal !== null) {
      this.videoSourceChanged = true;
    }
  }
}
