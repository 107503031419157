
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import SlideTitle from "@/components/SlideTitle.vue";
import anime from "animejs";

@Component({ components: { SlideTitle } })
export default class Partners extends Vue {
  @Ref() private readonly video!: HTMLVideoElement;
  @Prop({ required: true, type: Number })
  private progress!: number;
  private videoReady = false;
  private videoPlayed = false;
  private animations: anime.AnimeInstance[] = [];
  get maxAnimationDuration() {
    return this.animations.reduce((max, c) => Math.max(max, c.duration), 0);
  }

  get images() {
    const path = require.context("@/assets/logos", false, /\.svg$/);
    return path.keys().map(path);
  }

  private mounted() {
    this.video.load();
    this.video.onloadedmetadata = () => (this.videoReady = true);
    this.animations = [
      anime({
        targets: ["#slide-partners .brand"],
        scale: [0, 1],
        duration: 700,
        delay: (el, i) => i * 200,
        autoplay: false,
      }),
    ];
  }

  @Watch("progress")
  private onProgressChange(val: number) {
    if (val > 0 && this.videoReady && !this.videoPlayed) {
      this.video.play();
      this.videoPlayed = true;
    }
    this.animations.forEach((a) => a.seek(val * this.maxAnimationDuration));
  }
}
