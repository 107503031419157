
import { Component, Vue, Prop, Ref, Watch } from "vue-property-decorator";

@Component({})
export default class Artwork extends Vue {
  @Prop({ required: true, type: Number })
  private progress!: number;
  @Ref() private readonly video!: HTMLVideoElement;

  private runAnimationLoop = false;
  private videoReady = false;
  private timeout = 0;
  videoSource: string | null = null;
  videoPoster: string | null = null;
  private videoSourceChanged = false;

  private created() {
    this.setVideoUrl();
    window.addEventListener("orientationchange", this.setVideoUrl);
    window.addEventListener("resize", this.setVideoUrl);
  }

  private mounted() {
    this.video.load();
    this.video.onloadedmetadata = () => {
      this.videoReady = true;
    };

    this.video.onloadeddata = () => {
      this.$emit("loaded");
    };

    this.timeout = setInterval(() => {
      const video = this.$refs.video as HTMLVideoElement;
      if (video && this.videoSourceChanged) {
        video.pause();
        video.load();
        this.videoSourceChanged = false;
      }
    }, 1000);
  }

  private destroyed() {
    clearInterval(this.timeout);
    window.removeEventListener("orientationchange", this.setVideoUrl);
    window.removeEventListener("resize", this.setVideoUrl);
  }

  private setVideoUrl() {
    this.videoSource =
      window.innerHeight > window.innerWidth
        ? "/videos/drop-in-portrait.mp4"
        : "/videos/drop-in-desktop.mp4";
    this.videoPoster =
      window.innerHeight > window.innerWidth
        ? require("@/assets/img/posters/drop-in-portrait.jpg")
        : require("@/assets/img/posters/drop-in.jpg");
  }

  @Watch("videoSource")
  private onVideoSourceChanged(newVal: string, oldVal: string | null) {
    if (oldVal !== null) {
      this.videoSourceChanged = true;
      this.videoReady = false;
    }
  }

  private updateVideoTime() {
    const videoProgress = this.progress * (this.video.duration - 0.1);
    this.video.currentTime = videoProgress;
    if (this.runAnimationLoop) {
      window.requestAnimationFrame(this.updateVideoTime);
    }
  }

  @Watch("progress")
  private onProgressChange() {
    if (!this.videoReady) {
      return;
    }
    if (this.progress > 0 && this.progress < 1) {
      this.runAnimationLoop = true;
      window.requestAnimationFrame(this.updateVideoTime);
    } else {
      this.runAnimationLoop = false;
    }
    //this.updateVideoTime();
  }
}
