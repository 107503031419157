
import Vue from "vue";
import Component from "vue-class-component";
import { Ref, Prop, Watch } from "vue-property-decorator";
import anime from "animejs";

@Component({})
export default class ContactAnimation extends Vue {
  @Prop({ required: true, type: Boolean }) runAnimation!: boolean;
  @Ref()
  cow!: HTMLElement;
  @Ref() ray!: HTMLElement;
  @Ref() shadow!: HTMLElement;
  @Ref() saucer!: HTMLElement;
  width = 512;
  height = 350;
  animationStarted = false;

  get viewBox() {
    return `0 0 ${this.width} ${this.height}`;
  }

  @Watch("runAnimation", { immediate: true })
  onRunAnimationChanged(val: boolean) {
    if (!val || this.animationStarted) return;

    this.animationStarted = true;

    this.$nextTick(() => {
      const animation = anime
        .timeline({ autoplay: true })
        .add({
          targets: this.ray,
          scale: [0, 1],
          duration: 4000,
          delay: 0,
          easing: "easeInOutQuad",
        })
        .add({
          targets: this.cow,
          translateX: [0, 60],
          translateY: [0, 320 - this.height],
          rotate: "-20deg",
          scale: [1, 0.5],
          duration: 3000,
          delay: 0,
          easing: "easeInOutQuad",
        })
        .add(
          {
            targets: this.shadow,
            scale: [1, 0],
            duration: 1000,
            delay: 0,
            easing: "easeInOutQuad",
          },
          4000
        );
      animation.finished.then(() => {
        anime({
          targets: this.cow,
          translateY: ["+=3"],
          easing: "easeInOutQuad",
          loop: true,
          direction: "alternate",
        });
      });
    });
  }
}
