
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import SlideTitle from "@/components/SlideTitle.vue";
import anime from "animejs";

@Component({ components: { SlideTitle } })
export default class Team extends Vue {
  @Prop({ required: true, type: Number })
  private progress!: number;
  @Ref() tilesWrapper!: HTMLDivElement;
  @Ref() private readonly video!: HTMLVideoElement;
  private animationStarted = false;
  displayArrows = false;
  timeout = 0;
  videoReady = false;
  videoPlayed = false;
  videoEnded = false;

  private mounted() {
    this.video.load();
    this.video.onloadedmetadata = () => (this.videoReady = true);
    this.video.onplaying = () => {
      const duration = this.video.duration;
      const width = this.tilesWrapper.clientWidth;
      const totalWidth = this.tilesWrapper.scrollWidth;

      anime({
        targets: this.tilesWrapper,
        scrollLeft: totalWidth - width,
        duration: duration * 900,
        delay: 500,
        easing: "easeInOutQuad",
      });
    };

    this.$nextTick(() => {
      if (this.tilesWrapper.scrollWidth > this.tilesWrapper.clientWidth) {
        this.displayArrows = true;
      }
    });
  }

  private destroyed() {
    clearInterval(this.timeout);
  }

  @Watch("progress")
  private onProgressChange(val: number) {
    if (val > 0.2 && this.videoReady && !this.videoPlayed) {
      this.video.play();
      this.videoPlayed = true;
    }
  }

  scrollTiles(isRight: boolean) {
    const currentLeft = this.tilesWrapper.scrollLeft;
    const width = this.tilesWrapper.clientWidth;
    const newLeft = isRight
      ? Math.max(currentLeft - width, 0)
      : currentLeft + width;

    this.tilesWrapper.scrollTo({
      left: newLeft,
      behavior: "smooth",
    });
  }

  replayVideo() {
    this.videoEnded = false;
    anime({
      targets: this.tilesWrapper,
      scrollLeft: 0,
      duration: 1000,
      easing: "easeInOutQuad",
      complete: () => {
        this.video.play();
      },
    });
  }
}
