
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import anime, { AnimeTimelineInstance } from "animejs";
import ContactAnimation from "@/components/ContactAnimation.vue";

@Component({ components: { ContactAnimation } })
export default class Contact extends Vue {
  @Prop({ required: true, type: Number })
  progress!: number;
  showLinks = false;
  private animation!: AnimeTimelineInstance;
  runUfoAnimation = false;

  private mounted() {
    this.animation = anime
      .timeline({ autoplay: false })
      .add({
        targets: this.$refs.rocket,
        left: ["-100vw", "100vw"],
        top: ["100vw", "-100vw"],
        duration: 2000,
        delay: 1000,
        easing: "linear",
      })
      .add(
        {
          targets: [this.$refs.meteor1, this.$refs.meteor2],
          left: ["100vw", "-100vw"],
          top: ["-100vw", "100vw"],
          duration: 2000,
          delay: anime.stagger(500),
          easing: "easeInQuad",
        },
        100
      )
      .add(
        {
          targets: this.$refs.rays,
          opacity: [0, 1],
          duration: 2000,
          easing: "easeInQuad",
        },
        0
      );
  }

  @Watch("progress")
  private onProgressChange(val: number) {
    if (val > 0.5) this.runUfoAnimation = true;
    if (val > 0.8) {
      this.showLinks = true;
      this.animation.seek(this.animation.duration);
    } else {
      this.animation.seek((val * this.animation.duration) / 0.8);
      this.showLinks = false;
    }
  }
}
