
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class SlideTitle extends Vue {
  @Prop({ required: true, type: String }) title!: string;
  @Prop({ required: false, type: String }) color!: string;
}
