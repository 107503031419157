
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import SlideTitle from "@/components/SlideTitle.vue";
import anime from "animejs";
// Import Swiper Vue.js components
import Swiper, { Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

@Component({ components: { SlideTitle } })
export default class Portfolio extends Vue {
  @Prop({ required: true, type: Number })
  progress!: number;
  animationStarted = false;
  popupVideos: string[] | null = null;
  isLandscape = false;
  swiper: Swiper | null = null;

  portfolio = {
    content: [
      "https://player.vimeo.com/video/891530098",
      "https://player.vimeo.com/video/607516495",
      "https://player.vimeo.com/video/800003216",
    ],
    influencer: [
      "https://player.vimeo.com/video/901824344",
      "https://player.vimeo.com/video/775010012",
      "https://player.vimeo.com/video/891790491",
      "https://player.vimeo.com/video/901816177",
      "https://player.vimeo.com/video/797296490",
    ],
    digital: [
      "https://player.vimeo.com/video/607517768",
      "https://player.vimeo.com/video/797124193",
      "https://player.vimeo.com/video/891530098",
      "https://player.vimeo.com/video/920220698",
    ],
    retail: [
      "https://player.vimeo.com/video/607521710",
      "https://player.vimeo.com/video/892306587",
    ],
    audio: ["https://player.vimeo.com/video/938583935"],
    video: [
      "https://player.vimeo.com/video/796983837",
      "https://player.vimeo.com/video/796681469",
      "https://player.vimeo.com/video/518685030",
    ],
    promos: ["https://player.vimeo.com/video/631118952"],
    branding: ["https://player.vimeo.com/video/657577267"],
    crm: ["https://player.vimeo.com/video/796690194"],
    fixtures: ["https://player.vimeo.com/video/668577938"],
    events: [
      "https://player.vimeo.com/video/796681030",
      "https://player.vimeo.com/video/920208495",
    ],
  };

  created() {
    this.setOrientation();
  }

  mounted() {
    window.addEventListener("orientationchange", this.setOrientation);
    window.addEventListener("resize", this.setOrientation);

    this.swiper = new Swiper(this.$refs.swiper as HTMLElement, {
      modules: [Pagination],
      slidesPerView: "auto",
      direction: "vertical",
      enabled: false,
      breakpoints: {
        "@1.00": {
          direction: "horizontal",
          pagination: {
            el: this.$refs.swiperPagination as HTMLElement,
            clickable: true,
          },
          enabled: true,
        },
      },
    });
  }

  openVideo(id: keyof typeof this.portfolio | null) {
    if (id == null) {
      this.popupVideos = null;
      document.body.classList.remove("modal-open");
      return;
    }

    this.popupVideos = this.portfolio[id];

    window.fbq &&
      window.fbq("track", "ViewContent", {
        content_category: "CaseStudy",
        content_name: id,
      });
    document.body.classList.add("modal-open");
    this.$nextTick(() => {
      new Swiper(this.$refs.swiperVideo as HTMLElement, {
        modules: [Pagination, Navigation],
        slidesPerView: 1,
        pagination: {
          el: this.$refs.videoSwiperPagination as HTMLElement,
        },
        navigation: {
          nextEl: this.$refs.nextVideoLink as HTMLElement,
          prevEl: this.$refs.prevVideoLink as HTMLElement,
        },
      });
    });
  }

  @Watch("progress")
  onProgressChange(val: number) {
    if (val > 0.5 && !this.animationStarted) {
      this.animationStarted = true;
      anime({
        targets: this.$refs.slides,
        opacity: [0, 1],
        translateX: [500, 0],
        duration: 2000,
        delay: (el, i) => i * 200,
        autoplay: true,
      });
    }
  }

  setOrientation() {
    this.isLandscape = window.innerWidth > window.innerHeight;
  }

  destroyed() {
    this.swiper?.destroy();
    window.removeEventListener("orientationchange", this.setOrientation);
    window.removeEventListener("resize", this.setOrientation);
  }
}
