
import { Component, Vue } from "vue-property-decorator";
import Home from "@/slides/Home.vue";
import AboutUs from "@/slides/AboutUs.vue";
import Artwork from "@/slides/Artwork.vue";
import Partners from "@/slides/Partners.vue";
import Team from "@/slides/Team.vue";
import Portfolio from "@/slides/Portfolio.vue";
import Contact from "@/slides/Contact.vue";
import Slide from "@/components/Slide.vue";

@Component({
  components: { Slide },
})
export default class App extends Vue {
  loadedSlides: { [k: string]: boolean } = {};
  get slides() {
    return {
      Home,
      AboutUs,
      Artwork,
      Portfolio,
      Partners,
      Team,
      Contact,
    };
  }

  setSlideLoaded(key: string) {
    this.$set(this.loadedSlides, key, true);
  }
}
