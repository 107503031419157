
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Slide extends Vue {
  progress: number | null = null;

  private mounted() {
    this.onScrollChanged();
    window.addEventListener("scroll", this.onScrollChanged);
  }

  private onScrollChanged() {
    const height = document.documentElement.clientHeight;
    const rect = this.$el.getBoundingClientRect();
    const totalToScroll = rect.height;
    const currentPostion = height - rect.top;
    this.progress = Math.min(Math.max(currentPostion / totalToScroll, 0), 1);
  }
}
